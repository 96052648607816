import React from 'react';
import Navbar from '../../component/Navbar';
import FollowHeart from '../FollowHeart';
import Footer from '../../component/Footer';

const HeartRoute = () => {
  return (
    <div>
      <Navbar />
      <FollowHeart />
      <Footer />
    </div>
  );
};

export default HeartRoute;
