import React from 'react';
import Navbar from '../../component/Navbar';
import Approach from '../Approach';
import Footer from '../../component/Footer';

const ApproachRoute = () => {
  return (
    <div>
      <Navbar />
      <Approach />
      <Footer />
    </div>
  );
};

export default ApproachRoute;
