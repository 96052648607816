import React from 'react';
import { Typography } from '@material-ui/core';
import './Text.css';
import ford from '../asset/for.png';
import micro from '../asset/MS.png';
import uke from '../asset/uke.png';

import baker from '../asset/baker.png';
import wat from '../asset/wat.png';
import nass from '../asset/nass.png';

const Text = () => {
  return (
    <div className='text_logo'>
      <p className='text_text'>
        <span>We</span> Are <span>Supported </span> By
      </p>
    </div>
  );
};

export default Text;
