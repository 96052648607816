import './App.css';
import Home from './Home';
import { Routes, Route } from 'react-router-dom';
import AboutusRoute from './compo/Route/AboutusRoute';

import ApproachRoute from './compo/Route/ApproachRoute';
import AboutcultureRoute from './compo/AboutcultureRoute';

import HeartRoute from './compo/Route/HeartRoute';
import React from 'react';
function App() {
  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='aboutus' element={<AboutusRoute />} />
        <Route path='approaches' element={<ApproachRoute />} />
        <Route path='aboutcultureroute' element={<AboutcultureRoute />} />
        <Route path='heart' element={<HeartRoute />} />
      </Routes>
    </div>
  );
}

export default App;
