import React from 'react';
import Budgeting from './component/Budgeting';
import Images from './component/Images';
import Navbar from './component/Navbar';
import Pay from './component/Pay';
import Payvendor from './component/Payvendor';
import Student from './component/Student';
import SubNav from './component/SubNav';

import Footer from './component/Footer';
import Aboutus from './compo/Aboutus';
import Text from './component/Text';
import Explore from './component/Explore';
import Follow from './component/Follow';
import Cards from './component/Cards';
import Slider from './component/Slider';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const Home = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <div>
        <Navbar />
        <SubNav />
        <Text />
        <Slider />
        <Student />
        <Payvendor />
        <Budgeting />
        <Pay />
        <Cards />
        <Explore />
        <Follow />
        <Footer />
      </div>
    </>
  );
};

export default Home;
