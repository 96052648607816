import React from 'react';
import './Footer.css';
import Dot from '../asset/Dotlogo.png';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedlnIcon from '@material-ui/icons/LinkedIn';

const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer__container'>
        <img src={Dot} alt='' />
        <div className='icons'>
          <a href='https://www.linkedin.com/company/dot-inc/' target='_blank'>
            <LinkedlnIcon className='icons_2' />
          </a>
          <a href=' https://www.instagram.com/dot_fyh/' target='_blank'>
            <InstagramIcon className='icons_1' />
          </a>

          <TwitterIcon className='icons_3' />
        </div>
        <h3>Protected Page</h3>

        <p className='footer__2'>&copy; 2022 Dot Inc. All right reserved.</p>
        <p>
          This is property of Dot Inc. Any information contained on this website
          is provided to you for informational purposes only and should not be
          regarded as an offer or solicitation of an offer to buy or sell any
          investments or related services that may be referenced here. If you
          have any issues against this website, contact info@dotinc.app
        </p>
      </div>
    </div>
  );
};

export default Footer;
