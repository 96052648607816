import React, { useState } from 'react';
import {
  AppBar,
  Paper,
  Tab,
  Tabs,
  Toolbar,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import logo from '../asset/logo1.png';
import logo2 from '../asset/logo2.png';
import { COLORS, SIZES } from '../constants/theme';
import DrawercComp from './DrawercComp';
import { Link as Link1 } from 'react-router-dom';
import { Link as Link2 } from 'react-scroll';
import './Navbar.css';
const Navbar = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down('md'));
  const [value, setValue] = useState();
  return (
    <>
      <div style={{ width: '100%' }}>
        <div style={{ maxWidth: '1440px' }}>
          <AppBar
            className='appbar'
            style={{ backgroundColor: '#000', position: 'fixed' }}
          >
            <Toolbar>
              <Link1 to={'/'}>
                <img src={logo} alt='logo' className='logos' />
              </Link1>
              {isMatch ? (
                <>
                  <DrawercComp />
                </>
              ) : (
                <>
                  <Tabs
                    value={value}
                    onChange={(e, value) => setValue(value)}
                    TabIndicatorProps={{
                      style: {
                        background: COLORS.secondary,
                      },
                    }}
                    style={{ marginLeft: 'auto' }}
                    textColor='secondary'
                  >
                    <Tab
                      label='Home'
                      style={{ color: COLORS.primary }}
                      component={Link1}
                      to={'/'}
                    />
                    <Tab
                      label='About Us'
                      style={{ color: COLORS.primary }}
                      component={Link1}
                      to={'/aboutus'}
                    />

                    <Link2
                      to='payvendor'
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact='true'
                      offset={-80}
                    >
                      <Tab
                        label='Features'
                        style={{ color: COLORS.primary, fontWeight: '700' }}
                      />
                    </Link2>
                    <Link2
                      to='community'
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact='true'
                      offset={-80}
                    >
                      <Tab
                        label='Join Community'
                        style={{ color: COLORS.primary }}
                      />
                    </Link2>
                  </Tabs>
                  <Paper
                    elevation={0}
                    style={{
                      marginLeft: 'auto',
                      backgroundColor: COLORS.secondary,
                      display: 'flex',
                      padding: '0.8rem 2rem',
                      borderRadius: 48,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={logo2}
                      alt='logo2'
                      style={{ marginRight: 10, width: 21, height: 24 }}
                    />
                    <Typography style={{ color: COLORS.primary, fontSize: 16 }}>
                      Download the Dot App
                    </Typography>
                  </Paper>
                </>
              )}
            </Toolbar>
          </AppBar>
        </div>
      </div>
    </>
  );
};

export default Navbar;
