import React from 'react';
import './Team.css';
import Steve from '../asset/stephen.jpg';
import Abdu from '../asset/abdu.jpg';
import Frank from '../asset/frank.jpeg';
import Tamuda from '../asset/tamuda.jpeg';
import Mike from '../asset/mike.jpeg';
import Ayanfe from '../asset/Ayanfe.png';

const Team = () => {
  return (
    <div className='team'>
      <div className='team_container'>
        <div style={{ width: '100%' }}>
          <div className='team_content'>
            <div className='team_header'>
              <div>
                <h1>The Team</h1>
              </div>
              <div className='team__grids'>
                <div className='team_grids'>
                  <div>
                    <div className='team_grid'>
                      <img src={Abdu} alt='' />
                    </div>
                    <div>
                      <h4 style={{ color: '#AE1EC7' }}>Abdulrasaq Amolegbe</h4>
                      <p>Co-founder/CEO</p>
                    </div>
                  </div>
                  {/* secon */}
                  <div>
                    <div className='team_grid'>
                      <img src={Tamuda} alt='' className='tamu' />
                    </div>
                    <div>
                      <h4 style={{ color: '#AE1EC7' }}>Chimhanda Tamuda</h4>
                      <p>Co-founder/Design Lead</p>
                    </div>
                  </div>
                  {/* third */}
                  <div>
                    <div className='team_grid'>
                      <img src={Frank} alt='' />
                    </div>
                    <div>
                      <h4 style={{ color: '#AE1EC7' }}>Francis Ibe</h4>
                      <p>Senior Developer</p>
                    </div>
                  </div>

                  {/* fourth */}
                  <div>
                    <div className='team_grid'>
                      <img src={Mike} alt='' />
                    </div>
                    <div>
                      <h4 style={{ color: '#AE1EC7' }}>Michael Baron</h4>
                      <p>Backend Developer</p>
                    </div>
                  </div>

                  {/* fifth */}
                  <div>
                    <div className='team_grid'>
                      <img src={Ayanfe} alt='' />
                    </div>
                    <div>
                      <h4 style={{ color: '#AE1EC7' }}>Ayanfeoluwa Edun</h4>
                      <p>Product Designer</p>
                    </div>
                  </div>

                  <div>
                    <div className='team_grid'>
                      <img src={Steve} alt='' />
                    </div>
                    <div>
                      <h4 style={{ color: '#AE1EC7' }}>Adebayo Stephen</h4>
                      <p>Frontend Developer</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
