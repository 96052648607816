import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import { styled } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),

  color: theme.palette.text.secondary,
}));

export default function Mission() {
  return (
    <Grid alignItems='center' spacing={2} elevation={0}>
      <Grid
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '2rem',
          direction: 'row',
          justifyContent: 'center',
          marginTop: 100,
        }}
      >
        <Item elevation={0}>
          <Grid
            item
            style={{
              borderColor: '#09AD0E',
              border: '2px solid #09AD0E',
              borderRadius: 8,
            }}
            elevation={0}
          >
            <Card style={{ maxWidth: 345 }} elevation={0}>
              <CardContent>
                <Typography gutterBottom variant='h5' component='div'>
                  Mission
                </Typography>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  style={{ lineHeight: '26px', fontFamily: 'sans-serif' }}
                >
                  Our mission is to build a network of financial services on the
                  shoulders of student communities - built by students, for
                  students, and backed by student-run capital.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Item>
        <Item elevation={0}>
          <Grid
            item
            elevation={0}
            style={{
              borderColor: '#09AD0E',
              border: '2px solid #09AD0E',
              borderRadius: 8,
            }}
          >
            <Card style={{ maxWidth: 345 }} elevation={0}>
              <CardContent>
                <Typography gutterBottom variant='h5' component='div'>
                  Vision
                </Typography>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  style={{ lineHeight: '26px', fontFamily: 'sans-serif' }}
                >
                  We are on a quest to build a launchpad of financial services
                  that will empower the current and next generation of African
                  Gen-Zs to exercise their purchasing power.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Item>
      </Grid>
    </Grid>
  );
}
