import React from 'react';
import Explore from '../component/Explore';
import Navbar from '../component/Navbar';
import AboutCulture from './AboutCulture';
import Commmuicate from './Commmuicate';
import Values from './Values';
import Footer from '../component/Footer';
import Appdoc from './Appdoc';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const AboutcultureRoute = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Navbar />
      <AboutCulture />
      <Values />
      <Commmuicate />
      <br />

      <Footer />
    </div>
  );
};

export default AboutcultureRoute;
