import React from 'react';
import './Slider.css';
import ford from '../asset/for.png';
import micro from '../asset/MS.png';
import uke from '../asset/uke.png';

import baker from '../asset/baker.png';
import wat from '../asset/wat.png';
import nass from '../asset/nass.png';
const Slider = () => {
  return (
    <div className='slider-wrapper'>
      <div className='slider-track'>
        <div className='slide'>
          <img src={micro} alt='' className='text_img text-img1' />
        </div>
        <div className='slide'>
          <img src={nass} alt='' className='text_img text-img2' />
        </div>

        <div className='slide'>
          <img src={ford} alt='' className='text_img text-img3' />
        </div>
        <div className='slide'>
          <img src={wat} alt='' className='text_img text-img4' />
        </div>

        <div className='slide'>
          <img src={uke} alt='' className='text_img text-img5' />
        </div>
        <div className='slide'>
          <img src={baker} alt='' className='text_img text-img6' />
        </div>

        {/* second */}
        <div className='slide'>
          <img src={micro} alt='' className='text_img text-img1' />
        </div>
        <div className='slide'>
          <img src={nass} alt='' className='text_img text-img2' />
        </div>

        <div className='slide'>
          <img src={ford} alt='' className='text_img text-img3' />
        </div>
        <div className='slide'>
          <img src={wat} alt='' className='text_img text-img4' />
        </div>

        <div className='slide'>
          <img src={uke} alt='' className='text_img text-img5' />
        </div>
        <div className='slide'>
          <img src={baker} alt='' className='text_img text-img6' />
        </div>
      </div>
    </div>
  );
};

export default Slider;
