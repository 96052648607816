export const COLORS = {
  primary: '#FFFFFF',
  secondary: '#AE1EC7',
  lightgray: '#F2F2F2',
  tertiary: '#8A8A8A',
  red: '#D72222',
  green: '#09AD0E',
  light: '#F3D3F8',
  yellow: '#FFC107',
  black: '#000000',
  pink: '#F3D3F8',
  black: '#1E1E1E',
};

export const SIZES = {
  base: 8,
  smaller: 10,
  small: 12,
  font: 14,
  medium: 16,
  big: 20,
  large: 18,
  heavy: '700',
  extraLarge: 24,
};
