import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Menu,
} from '@material-ui/core';
import { Link as Link1 } from 'react-router-dom';
import { Link as Link2 } from 'react-scroll';
import MenuIcon from '@material-ui/icons/Menu';
import './Navbar.css';
import logo from '../asset/logo1.png';
import CloseIcon from '@material-ui/icons/Close';
import { HashLink } from 'react-router-hash-link';

const DrawercComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [clicked, setClicked] = useState(false);
  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor={'top'}
        style={{ position: 'static' }}
      >
        <List>
          <ListItem>
            <ListItemText>
              <Link1 to='/'>
                <img
                  src={logo}
                  alt=''
                  style={{
                    position: 'relative',
                    left: '0rem',
                    paddingBottom: '3rem',
                  }}
                />
              </Link1>
              <IconButton
                onClick={() => setOpenDrawer(!openDrawer)}
                style={{
                  position: 'absolute',
                  right: '0rem',
                  top: '1rem',
                }}
              >
                <CloseIcon />
              </IconButton>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemText>
              <Link1 to='/' className='nav'>
                Home
              </Link1>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemText>
              <Link1 to='/aboutus' className='nav'>
                About Us
              </Link1>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemText>
              <Link2
                onClick={() => setOpenDrawer(!openDrawer)}
                to='payvendor'
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}
                className='nav'
              >
                Features
              </Link2>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Link2
                onClick={() => setOpenDrawer(!openDrawer)}
                to='community'
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}
                className='nav'
              >
                Join Community
              </Link2>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <IconButton
        style={{ marginLeft: 'auto' }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon style={{ color: '#ffffff' }} />
      </IconButton>
    </>
  );
};

export default DrawercComp;
