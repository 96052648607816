import React from 'react';
import { Typography, Paper, Button } from '@material-ui/core';
import home from '../asset/home.png';
import { COLORS, SIZES } from '../constants/theme';
import logo2 from '../asset/logo2.png';
import './subnav.css';

const SubNav = () => {
  return (
    <>
      <div className='allSub'>
        <div className='subcontainer'>
          <div style={{ width: '100%' }}>
            <div className='sub_content'>
              <h1 className='sub-text'>
                Familiar banking experience for students in Africa
              </h1>
              <p className='sub---text'>
                Make direct payments to 300,000+ merchants, instant transfers to
                peers, and manage healthy spending habits. All within your Dot
                wallet.
              </p>
            </div>

            <div>
              <div className='dotapp'>
                <img src={logo2} alt='' className='sub_img' />
                <p style={{ color: '#ffffff' }}>Download the Dot App</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubNav;
