import React from 'react';
import './Pay.css';
import Rectangle from '../asset/Rectangle.png';
import po2 from '../asset/po2.png';

const Pay = () => {
  return (
    <div className='Pay'>
      <div className='Pay_container'>
        <div style={{ width: '100%' }}>
          <div className='Pay_content'>
            <div className='pay_image'>
              <div className='pay_img'>
                <img
                  src={Rectangle}
                  alt=''
                  className='Pay__img'
                  data-aos='fade-up'
                  data-aos-duration='2000'
                />
              </div>
            </div>
            <div>
              <div className='Pay__po'>
                <img src={po2} alt='' className='Pay_po' />
              </div>
              <div className='Pay_header'>
                <h1 className='pay_text'>Pay Friends</h1>
                <p className='Pay_text'>
                  Send and receive money to friends at the speed of messaging.
                  <br />
                  <p>
                    Dot also allows you to pay friends outside the Dot platform
                    through our partnerships with the top 5 banks in South
                    Africa.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pay;
