import React from 'react';
import Navbar from '../../component/Navbar';
import Aboutus from '../Aboutus';
import Culture from '../Culture';
import Mission from '../Mission';
import Team from '../Team';
import Footer from '../../component/Footer';

const AboutusRoute = () => {
  return (
    <div>
      <Navbar />
      <Aboutus />
      <Mission />
      <Culture />
      <Team />
      <Footer />
    </div>
  );
};

export default AboutusRoute;
