import React from 'react';
import './Budget.css';
import home3 from '../asset/home3.png';
import AOS from 'aos';
import { useEffect } from 'react';
import 'aos/dist/aos.css';
import Phones from '../asset/phones.png';

const Budgeting = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className='budget'>
      <div className='budget-container'>
        <div className='budget-content'>
          <div className='budget__head'>
            <h1 className='budget_header'>
              <span style={{ color: '#ae1ec7' }}>Personalized </span>
              <div>budgeting</div>
            </h1>
            <p className='budget_body'>
              Create budgets for multiple expenses like rent, school, grocery,
              fun, and so on. Use your budgets to track your expense.
              <p>
                Each budget allows you to only spend what you want, how you want
                it, and when you want it.
              </p>
            </p>
          </div>
          <div className='budget_img'>
            <img
              src={Phones}
              alt=''
              className='budget__img'
              data-aos='fade-down'
              data-aos-easing='linear'
              data-aos-duration='2500'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Budgeting;
