import React from 'react';
import './Cards.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
const Cards = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className='cards' id='community'>
      <div className='card_container'>
        <div className='card_content'>
          <div className='card_h1'>
            <h1>Experience community</h1>
          </div>
          <div className='all_cards'>
            <div className='cards_text'>
              <div
                className='cards__first'
                data-aos='flip-left'
                data-aos-easing='ease-out-cubic'
                data-aos-duration='10000'
              >
                <h1>
                  Talk The <br /> Walk
                </h1>

                <ul>
                  <li>Attend monthly personal finance workshops</li>
                  <li>
                    Follow social media for tips on managing your personal
                    finance
                  </li>
                  <li>Practice what we preach on the dot app</li>
                </ul>
              </div>
              <div
                className='cards__first'
                data-aos='flip-left'
                data-aos-easing='ease-out-cubic'
                data-aos-duration='1000'
              >
                <h1>
                  Walk The <br /> Talk
                </h1>

                <ul>
                  <li>Budget your spending</li>
                  <li>Keep track of your funds</li>
                  <li>Practice what we preach on the dot app</li>
                  <li>Keep your friends accountable</li>
                  <li>Budget with your friends</li>
                  <li>Receive a warning for off-budget expenses</li>
                </ul>
              </div>
              <div
                className='cards__first'
                data-aos='flip-left'
                data-aos-easing='ease-out-cubic'
                data-aos-duration='3000'
              >
                <h1>
                  Don't Do It
                  <br /> Alone
                </h1>

                <ul>
                  <li>Join Dot community on your campus</li>
                  <li>Win prizes at hackathons</li>
                  <li>Access exclusive discounts at retail stores</li>
                  <li>Invite your friends and earn rewards</li>
                </ul>
              </div>
            </div>
          </div>
          <div className='card_subtext'>
            <h1 className='card_text'>
              Find Out What’s Going On in Your School Community
            </h1>
            <a href=' https://www.instagram.com/dot_fyh/' target='_blank'>
              <div className='update'>
                <p className='update_text'>Stay Updated</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
