import React from 'react';

import './Student.css';
import { Paper, Typography, IconButton } from '@material-ui/core';
import study from '../asset/study.png';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import VideoBg from '../asset/videoBg.mp4';
import { COLORS } from '../constants/theme';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useRef } from 'react';
import YouTube from 'react-youtube';
const Student = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  const videoRef = useRef();

  const handlePlay = () => {
    videoRef.current.play();
  };
  const handlePause = () => {
    videoRef.current.pause();
  };
  const videoOptions = {
    playerVars: {
      autoplay: 1,
      controls: 1,
      rel: 0,
      showinfo: 0,
      mute: 0,
      loop: 1,
    },
  };
  return (
    <div className='student' data-aos='fade-up' data-aos-duration='3300'>
      <div className='student-container'>
        <div className='student-content'>
          <div className='sudy'>
            {/* <iframe
              // ref={videoRef}
              src={VideoBg}
              allow='autoplay; encrypted-media'
              allowFullScreen
              title='video'
              allowTransparency
              // autoPlay
              // loop
              frameBorder='0'
              className='videos'
            ></iframe> */}
            <YouTube
              videoId='NXDqALdCz0o'
              opts={videoOptions}
              className='videos'
            />
            {/* <div className='allbtn'>
                <button className='btn' onClick={handlePlay}>
                  Play
                </button>
                <button className='btn' onClick={handlePause}>
                  Pause
                </button>
              </div> */}
          </div>
          <div>
            <div className='text-1'>
              <h1 className='text--1'>
                By Students, <br />
                <span style={{ color: COLORS.secondary }}>For Students.</span>
              </h1>
            </div>
            <div className='text-2'>
              <p className='stud__text'>
                Dot creates banking experiences that allow students in Africa to
                seamlessly manage their finances.
              </p>
              <div
                style={{
                  borderRadius: 40,
                }}
                className='stu_button'
              >
                <p className='student__text'>Learn about our Approach</p>
                <KeyboardArrowRightIcon
                  className='student__icon'
                  style={{ marginLeft: 10, fontSize: 30, color: '#ffffff' }}
                />
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Student;
