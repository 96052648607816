import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import { styled } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CardHeader, Grid } from '@material-ui/core';
import images from '../asset/commu.png';
import './Commu.css';
import Group from '../asset/Group5.png';
import right from '../asset/right.png';
import left from '../asset/left.png';
import left2 from '../asset/left2.png';
import Appdoc from './Appdoc';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),

  color: theme.palette.text.secondary,
}));

export default function Mission() {
  return (
    <div className='commu'>
      <div className='commu_content'>
        <div>
          <div className='item'>
            <div className='all'>
              <div className>
                <h2
                  style={{
                    color: '#AE1EC7',
                    fontWeight: '700',
                    paddingBottom: 20,
                  }}
                >
                  Communication
                </h2>
                <p className='commu_text'>
                  Simple as this is, communication has been the single-highest
                  contributor of growth in this startup. Without this, there
                  will be no need for virtual account management system or
                  account-to-account payment that we are currently working on.
                  We've pivoted several times, had more rejections than I can
                  count, and more discouraging remarks than I can remember. What
                  kept us going was the fact that Tamuda and I stayed in
                  communication with our goals and beliefs.
                </p>
                <br />
                <p
                  variant='body2'
                  color='text.secondary'
                  className='commu_text'
                >
                  Not to say there weren't moments where we doubted, but those
                  doubts were recipes for curiosity, not discouraging remarks.
                  The more questions we asked, with the right mindset, the
                  faster we moved. Of course, curiosity is the beginning of
                  innovation. But curiosity with a wrong mindset is a recipe for
                  disaster.
                </p>
                <br />
                <p className='commu_text'>
                  In fact, great, excellent and effective communication is the
                  standard for bringing in any new person to the team. But it
                  would also be the red flag for letting people go.
                </p>
                <div className='left'>
                  <img src={left2} alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='commu_img'>
          <img src={images} alt='' className='communicate_image' />
        </div>
      </div>

      {/* second card */}
      <div className='communicate_text'>
        <div>
          <img src={Group} alt='' className='commit_image' />
        </div>
        <div className='allcommit'>
          <div className='commit'>
            <div className='commit_2'>
              <h2
                style={{
                  color: '#AE1EC7',
                  fontWeight: '700',
                  paddingBottom: 20,
                }}
              >
                Commitment
              </h2>
              <p className='commu_text'>
                My mantra is that "only the ones who are crazy enough to execute
                are the ones who actually change the world". Without execution,
                the world won't be where it is today and it is only okay to
                mention that while ideation is usually the beginning of
                innovation, execution takes it further. Ideation is defined by
                going zero-to-one and execution is defined by going from
                one-to-infinity.
              </p>

              <p className='commu_text'>
                As we double down on building, some questions like the following
                need to be clearly articulated:
              </p>

              <ul>
                <li>How many hours per week does Dot deserve from you?</li>
                <li>
                  What's the work structure? Am I working full-time or
                  part-time?
                </li>
                <li>What other responsibilities affect my work commitments?</li>
                <li>
                  How much am I willing to give to this? How much am I giving?
                </li>
                <li>What can I do better?</li>
                <li>How can Tamuda and Dulra help me to be better?</li>
                <li>
                  What support systems are needed to increase and improve my
                  efficiency.
                </li>
              </ul>
              <div className='right'>
                <img src={right} alt='' className='right_img' />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* third */}
      <div className='item2'>
        <div className='commit com'>
          <h1>Organisation</h1>
          <p className='commu_text'>
            This ties into being able to identify the bits and pieces needed to
            deliver on a particular task at a particular time. Timelines can
            never be over-emphasized. As the famous scientist once said, "Time
            is relative to the entity experiencing it". From my personal
            experience, I've only come to realize that he who ignores the
            essence of timing does so at his or her own disadvantage, especially
            when human behaviors are involved. The market is evolving at
            significant pace and if we slack on timing, we might be just too
            late. Working at our own pace is fine but might also be a disaster
            if we don't realize that the game ahead of us has changed. By the
            time we are ready, we'll be late.
          </p>

          <p className='commu_text'>
            Questions like are the beginning of any project cannot be
            over-emphasized:
          </p>

          <ul>
            <li>How long is it going to take to build XYZ?</li>
            <li>What's needed to reduce this time by half?</li>
            <li>What's likely to affect my delivery?</li>
            <li>What can speed things up?</li>
          </ul>
        </div>
      </div>
      {/* fourth */}
      <div className='communicate_text'>
        <div>
          <img src={left} alt='' className='commit_image' />
        </div>
        <div className='allcommit'>
          <div className='commit'>
            <div className='commit_2'>
              <h2
                style={{
                  color: '#AE1EC7',
                  fontWeight: '700',
                  paddingBottom: 20,
                }}
              >
                Blitzscaling
              </h2>
              <p className='commu_text'>
                This is a term by the founder of LinkedIn, Reid Hoffman.
                Blitzscaling is simply the act of placing speed over efficiency
                in an highly uncertain market. Here at Dot, this is one of our
                foremost mantra. By working incredibly fast and with little
                bottlenecks, we can shorten the time to get to market as well as
                reach product market fit. We prefer to move fast without
                breaking things. Things are gonna move pretty fast mostly
                because there’s no set of rigid rules about hierarchy or
                positions. Everyone is grinding day and night to build out what
                I call the African Dream- the dream to build a continent so
                great that its legacy will outlive its builders.
              </p>

              <p className='commu_text'>
                But only the ones who are crazy enough to execute are the ones
                who truly change the world.
              </p>

              <p className='commu_text'>
                I'm happy to have a conversation if you need to communicate
                anything. If I missed anything and you’d like to add to this,
                I'm more than happy to talk about it.
              </p>
              <div className='right'>
                <img src={right} alt='' className='right_img' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
