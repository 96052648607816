import React from 'react';
import './AboutCulture.css';
import Navbar from '../component/Navbar';
import culture from '../asset/groupabout.png';
import mobile from '../asset/mobile.png';

const AboutCulture = () => {
  return (
    <>
      <div className='aboutculture'>
        <div className='aboutculture_container'>
          <div style={{ width: '100%' }}>
            <div className='aboutculture_content'>
              <div className='culture__img'>
                <div>
                  <img src={culture} alt='' className='cul_img' />
                </div>
              </div>
              <div className='culture__text'>
                <div style={{ color: 'gray' }}>
                  <h5>CULTURE</h5>
                </div>
                <div className='header_approach'>
                  <h1>Dot's Culture</h1>
                </div>
                <p>Young Warlock,</p>
                <p>
                  If you are reading this doc, it means you have taken a huge
                  leap to join the team building the African Dream.
                </p>
                <p>I trust you are well.</p>
                <p>
                  This document lies on a spectrum of the work culture necessary
                  to thrive at the startup.
                </p>
                <p>
                  establishing the foundational technology of Dot. My heartfelt
                  appreciation and gratitude to you for all that you do and
                  represent. Thank you for coming this far, despite the odds!
                </p>
                <p>
                  With this said, I'd like to address a couple of things that
                  may either make or mar the future of the startup.
                </p>
                <p>
                  I understand that developers typically prefer to focus on
                  writing codes and less on getting bogged down in paperwork or
                  lots of meetings. While this is understandable, it becomes a
                  point of concern when the necessary culture needed to write
                  codes successfully are not put in place.
                </p>
                <p>Some of these include but not limited to:</p>

                <div className='mobile'>
                  <img src={mobile} alt='' className='cult_mobile' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCulture;
