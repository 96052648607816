import React, { useEffect } from 'react';
import './followheart.css';
import Heart from '../asset/Heart.png';
import Star from '../asset/Star.png';
import Arrow from '../asset/Arrow.png';
import Rectangle from '../asset/Rectangle.png';
import Phones from '../asset/phones.png';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
const FollowHeart = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className='heart'>
      <div className='heart_container'>
        <div className='heart_content'>
          <h1 className='heart_become'>
            Become <span style={{ color: '#000000' }}>A</span>
          </h1>
          <div className='heart_cont'>
            <img src={Heart} alt='' />
            <p>#FOLLOWYOURHEART</p>
            <img src={Heart} alt='' />
          </div>
          <div className='heart_sub'>
            <h1>
              Dot <span style={{ color: '#b625b1' }}>Fellow</span>
            </h1>
          </div>

          {/* cards */}
          <div className='all_heart'>
            <div className='heart_text'>
              <div className='heart__first '>
                <h1>Eligibility</h1>

                <ul className='heart_first1'>
                  <li>
                    Must be currently enrolled in an accredited
                    college/university
                  </li>
                  <li>
                    Experience launching a meaningful product/project from
                    scratch
                  </li>
                  <li>Self-starters eager to make an impact</li>
                  <li>
                    Position(s) of influence in campus organization or other
                    programs
                  </li>
                  <li>Open to students with any academic discipline.</li>
                </ul>
              </div>
              <div className='heart__first hearty_2'>
                <h1>
                  The Ideal <br /> Candidate
                </h1>

                <ul>
                  <li>
                    Self-motivated and hungry for a hands-on, brand ambassador
                    experience
                  </li>
                  <li>
                    Well connected on campus - plugged into student life, clubs,
                    and events
                  </li>
                  <li>
                    Trend-setter who knows how to engage on social media and IRL
                  </li>
                  <li>Highly organized, proactive communicator</li>
                  <li>Budget with your friends</li>
                  <li>
                    Believes in what we’re building at Dot, proud to rep it.
                  </li>
                </ul>
              </div>
              <div className='heart__first'>
                <h1>
                  What You Can
                  <br /> Expect
                </h1>

                <p>
                  We prioritize speed over efficiency. <br /> We dislike lots of
                  paperworks. We are a pro-student, pro-speed, pro-financial
                  inclusively
                </p>
                <p>
                  We are extremely bullish about faling, falling fast; learning,
                  learning fast.
                </p>
                <p>
                  We are super committed to building somethings users love
                  enough to tell others about and need enough to pay for it.
                </p>
              </div>
            </div>
          </div>
          {/* next */}
          <div className='heart_second'>
            <div className='heart_cir'></div>
            <div>
              <a href='https://dot-inc.notion.site/Application-For-Dot-Fellowship-7ca8571b9b144185b30677b4e27d0289'>
                <div className='heart_h3'>
                  <h3>APPLY HERE</h3>
                </div>
              </a>
              <div className='heart_sub2'>
                <img src={Star} alt='' />
                <h1>Benefits of Dot Fellowship</h1>
                <img src={Star} alt='' />
              </div>
            </div>
            <div className='heart_cir2'></div>
          </div>
          {/* next */}
          {/* cards */}
          <div className='heart2_all'>
            <div className='heart1'>
              <div className='heart-card2'>
                <h1>
                  Cash <br /> Rewards
                </h1>
              </div>

              <div className='heart-card2'>
                <h1>
                  Hone your <br /> leadership <br /> skills
                </h1>
              </div>
            </div>
            <div className='heart2'>
              <div className='heart-card2'>
                <h1>
                  Dot Merch & <br /> Swags
                </h1>
              </div>

              <div className='heart-card2'>
                <h1>
                  Grow your <br /> social presence, <br /> online and <br />
                  on-campus
                </h1>
              </div>
            </div>
            <div className='heart3'>
              <div className='heart-card2'>
                <h1>
                  Chance to win <br /> the Dot FYH <br /> Grand Prize
                </h1>
              </div>
            </div>
          </div>
          <div className='heart__3'>
            <img src={Arrow} alt='' />
            <h1>How does it work?</h1>
          </div>
          {/* next */}
          <div className='heart_launch'>
            <div className='heart_launchimg'>
              <img src={Rectangle} alt='' />
            </div>
            <div>
              <h1>
                Launch <span style={{ color: '#AE1EC7' }}>Basecamp </span>
              </h1>
              <ul>
                <li>Fellows organize a basecamp/conference on campus</li>
                <li>
                  Camp/conference could include workshops on personal finance,
                  guests speakers and lots of activities
                </li>
                <li>Announce new offers and promotions</li>
                <li>Introduce the dot application at the end of the event</li>
              </ul>
            </div>
          </div>
          <div className='heart_user'>
            <div>
              <h1>
                User <span style={{ color: '#AE1EC7' }}>Onboarding</span>
              </h1>
              <ul>
                <li>
                  After basecamp, fellows double down on onboarding users unto
                  the platform.
                </li>
                <li>Fellows share dot in friend groups, meetings.</li>
                <li>Fellows share college referral link to groups</li>
                <li>Fellows help students with FAQs</li>
                <li>Fellows forward any concern to the main team</li>
              </ul>
            </div>
            <div className='user_img'>
              <img src={Phones} alt='' />
            </div>
          </div>
          {/* next */}
          <div className='heart_launch hearty'>
            <div className='heart_launchimg'>
              <img src={Rectangle} alt='' />
            </div>
            <div>
              <h1>
                Community <span style={{ color: '#AE1EC7' }}>Shepard</span>
              </h1>
              <ul>
                <li>Oranizing monthly events</li>
                <li>Networking activities on campus</li>
                <li>Personal Finance Workshops</li>
                <li>Monthly Hackathons</li>
                <li>Social Media Featuring</li>
                <li>Build social media following for their campus</li>
              </ul>
            </div>
          </div>
          {/* next */}
          <div className='heart_user'>
            <div>
              <h1 style={{ textAlign: 'left' }}>
                User <span style={{ color: '#AE1EC7' }}>Feedback</span>
              </h1>
              <ul>
                <li>Short user interview</li>
                <li>IG surverys, quizzes,or polls</li>
              </ul>
            </div>
            <div className='user_img'>
              <img src={Phones} alt='' />
            </div>
          </div>
          {/* next */}
          <div className='heart_launch hearty'>
            <div className='heart_launchimg'>
              <img src={Rectangle} alt='' />
            </div>
            <div>
              <h1>
                Manage <span style={{ color: '#AE1EC7' }}>Discount</span> <br />
                Shop
              </h1>
              <ul className='discount'>
                <li>Weekends discounts</li>
                <li>Invite and Referrals</li>
                <li>Promotions on purchase e.g Apple Music</li>
              </ul>
            </div>
          </div>
          <a href='https://dot-inc.notion.site/Application-For-Dot-Fellowship-7ca8571b9b144185b30677b4e27d0289'>
            <div className='apply'>
              <h4>APPLY HERE TO BECOME A DOT FELLOW</h4>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FollowHeart;
