import React from 'react';
import './Payvendor.css';
// import po from '../asset/po.png';
import Rectangle from '../asset/Rectangle.png';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { useEffect } from 'react';

const Payvendor = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <section className='allcontainer' id='payvendor'>
        <div className='container'>
          <div className='content-1'>
            <div>
              {/* <div className='po'>
                <img src={po} alt='' className='po-1' />
              </div> */}
              <div className='Rect'>
                <img
                  src={Rectangle}
                  alt=''
                  className='Rect-1'
                  data-aos='fade-up'
                  data-aos-duration='2000'
                />
              </div>
            </div>

            <div className='student-header'>
              <h1 className='header'>
                Pay <span style={{ color: '#ae1ec7' }}>Vendors</span>
              </h1>
              <p className='student_text'>
                Make payments to 300,000+ merchants directly from your Dot
                wallet. For physical payment, Dot is integrated to allow users
                to easily scan at checkouts.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Payvendor;
