import React from 'react';
import Navbar from '../component/Navbar';
import './Aboutus.css';
import group from '../asset/group.png';
import groups from '../asset/group2.png';
import Mission from './Mission';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
const Aboutus = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <div className='Aboutus'>
        <div className='about_container'>
          <div style={{ width: '100%' }}>
            <div className='about_content'>
              <div>
                <img src={group} alt='' className='group1' />
              </div>
              <div>
                <h1>
                  We are creating
                  <span> seamless banking </span> experience for
                  <span> students in Africa</span>
                </h1>
              </div>
              <div>
                <img src={groups} alt='' className='group' />
              </div>
            </div>
            <div>
              <p className='about_text'>
                Dot is built to allow students in Africa to make direct
                payments, instant transfers, and manage healthy spending habits.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aboutus;
