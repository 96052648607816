import React from 'react';
import './Culture.css';
import meeting from '../asset/meeting.png';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Link } from 'react-router-dom';

const Culture = () => {
  return (
    <div className='culture'>
      <div className='culture_container'>
        <div style={{ width: '100%' }}>
          <div className='back'>
            <div className='culture_content'>
              <div className='culture_con'>
                <div>
                  <div>
                    <h1 className='culture_head'>Our Culture</h1>
                    <p className='culture_page'>
                      Dot’s is passionate about creating financial freedom for
                      the emerging youths of the African community. To achieve
                      this at a granular level, Dot has for core values that
                      drives the company culture...
                    </p>
                  </div>
                </div>
                <div>
                  <img src={meeting} alt='' className='culture_img' />
                </div>
              </div>
              <div style={{ marginLeft: 40 }}>
                <div className='learn'>
                  <div className='culture_text'>
                    <Link
                      to={'/aboutcultureroute'}
                      className='culture_textline'
                    >
                      <p className='btnclick'>Learn about our culture</p>
                    </Link>
                    <KeyboardArrowRightIcon className='culture__icon' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Culture;
