import React from 'react';
import './Approach.css';
import person from '../asset/person.png';
import phonefoot from '../asset/home.png';
import logo2 from '../asset/logo2.png';
import Appdoc from './Appdoc';
import Explore from '../component/Explore';

const Approach = () => {
  return (
    <div className='approach'>
      <div className='approach_container'>
        <div style={{ width: '100%' }}>
          <div className='approach_content'>
            <div className='approach_text'>
              <div style={{ color: 'gray' }}>
                <h5>Approach</h5>
              </div>
              <div className='header_approach'>
                <h1>Follow Your Heart and Do odd things</h1>
              </div>
              <p>
                The head and the heart have a few things in common aside from
                the fact that they both start with “h.”
              </p>
              <p>
                But when it comes to doing odd things, there’s something
                worthwhile difference between the duo.
              </p>
              <p>
                In a world where authenticity is always the runner-up, following
                your head instead of your heart is normal.
              </p>
              <p>
                But looking at both organs, the head has a different modus
                operandi from the heart.
              </p>
              <p>
                The head alone has 7+ input channels to take in information.
                Because of this, the head usually reacts to the stimulus, and
                whatever information is taken in through these 7+ inputs.
              </p>
              <p>
                The heart, on the other hand, goes a stretch to stimulate the
                reaction. Even when impure blood is delivered to the heart, the
                impure blood returns to the body nourished with oxygen. And
                guess what feeds the brain with oxygen? The heart.
              </p>
              <p>
                Also, the head is exposed directly to external
                factors/environment, causing it to be under the whims and
                caprices of life.
              </p>
              <p>
                The heart on the other hand is well protected by layers of bones
                and tissues.
              </p>
              <p>
                As such, the heart draws its energy from within, while the head
                draws from outside.
              </p>
              <p>
                Doing odd things requires more than being strong-headed. It’s a
                pledge to challenge the status quo, with humility and a
                commitment to follow your heart.
              </p>
              <div className='approach_img'>
                <img
                  src={person}
                  alt=''
                  style={{ width: '70px', height: '70px' }}
                />
                <div className='app'>
                  <h4>Abdulrasaq</h4>
                  <p>CEO</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Explore />
    </div>
  );
};

export default Approach;
