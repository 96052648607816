import React from 'react';
import phonefoot from '../asset/phone.png';
import logo2 from '../asset/appdoc.png';
import './Explore.css';
import video from '../asset/dot home.mp4';
import { COLORS } from '../constants/theme';
import { Link } from 'react-router-dom';

const Explore = () => {
  return (
    <div className='explore'>
      <video src={video} className='explore_video' autoPlay loop muted />

      <div className='explore_content'>
        <h1 className='explore_h1'>
          The <span style={{ color: COLORS.secondary }}>Dot </span>
          fellowship
        </h1>
        <p className='explore_text'>
          Are you a community builder, interested in leading and financial
          literacy communities in your school?
        </p>
        <div className='explore-doc'>
          <Link to={'heart'} style={{ textDecoration: 'none' }}>
            <div className='explore_doc'>
              <p className='app_tex'>Join the fellowship</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Explore;
