import React from 'react';
import './follow.css';
import carol from '../asset/carol.png';
import emmual from '../asset/emmual.png';
import indy from '../asset/indy.png';
import iwaria from '../asset/iwaria.png';
import AOS from 'aos';
import { useEffect } from 'react';
import 'aos/dist/aos.css';
const Follow = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className='follow'>
      <div className='follow_container'>
        <div className='follow_content'>
          <div className='follow_text'>
            <h1 data-aos='zoom-in' data-aos-duration='1000'>
              Follow <br /> your heart <br /> today.
            </h1>

            <div className='follow_email'>
              <input
                type='email'
                name='email'
                className='email'
                placeholder='Enter email address'
              />
            </div>
            <div className='join'>
              <p>Join Mailing List</p>
            </div>
          </div>
          <div
            className='follow_img'
            data-aos='zoom-in'
            data-aos-duration='1000'
          >
            <img src={carol} alt='' className='follow_image' />
            <img src={iwaria} alt='' className='follow_image' />
            <img src={emmual} alt='' className='follow_image' />
            <img src={indy} alt='' className='follow_image' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Follow;
